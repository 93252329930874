import {
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getActivities, getAlbums, getAuthors } from "../services/data.service";
import { useNavigate, useParams } from "react-router";
import AuthorInfo from "./AuthorInfo";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";

const Author = () => {
  const [authors, setAuthors] = useState({});
  const [loading, setLoading] = useState(false);

  const [albums, setAlbums] = useState([]);
  const [activities, setActivities] = useState({});

  const [photosCount, setPhotosCount] = useState(0);
  const [albumsIds, setAlbumsIds] = useState([]);

  const { authorID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getAuthors()
      .then((authors) => {
        setAuthors(authors);
      })
      .finally(() => setLoading(false));

    Promise.all([getAlbums(), getActivities()]).then(([albums, activities]) => {
      setAlbums(albums);
      setActivities(activities);
    });
  }, []);

  useEffect(() => {
    let count = 0;
    let ids = [];
    albums.forEach((a) => {
      let authorPhotosInAlbum = a.photos.filter(
        (p) => p.author === authorID
      ).length;

      count += authorPhotosInAlbum;

      if (authorPhotosInAlbum > 0) {
        ids.push(a.name);
      }
    });
    setPhotosCount(count);
    setAlbumsIds(ids);
  }, [albums, activities, authorID]);

  return (
    <>
      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}
      {authors && authors[authorID] && (
        <Paper variant="outlined" sx={{ px: 5, pb: 5 }}>
          <AuthorInfo author={authors[authorID]} />

          {photosCount > 0 && (
            <>
              <Divider />
              <Typography
                variant="subtitle1"
                align="center"
                sx={{ my: 2 }}
              >{`${photosCount} fotos en FOTONOBA. `}</Typography>
            </>
          )}

          {albumsIds.length > 0 && (
            <>
              <Typography variant="subtitle1" align="center">
                Podés verlas en los siguientes álbumes de la galería:
              </Typography>
              <List>
                {albumsIds.map((a) => (
                  <ListItemButton onClick={() => navigate(`/gallery/${a}`)}>
                    <ListItemIcon>
                      <PhotoLibraryIcon />
                    </ListItemIcon>
                    <ListItemText primary={activities[a].name} />
                  </ListItemButton>
                ))}
              </List>
            </>
          )}
        </Paper>
      )}
    </>
  );
};

export default Author;
